<template>
    <div class="header">
        <div class="logo">
            <div class="fl">数字白云</div>
            <div class="line fl"></div><span class="fl assistant">白云区村经济发展留用地</span>
        </div>
        <el-tabs class="nav" v-model="activeMenu" @tab-click="menuClick" :before-leave="beforeLeave">
            <el-tab-pane v-for="n in menus" :label="n.name" :name="n.code">
                <div slot="label" class="item">
                    <i :class="`iconfont  ${n.icon}`"></i>
                    {{ n.name }}
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dropdown class="user">
            <div style="cursor:pointer;color:#fff;">
                {{ userInfo.userName }} ({{ userInfo.name }})
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="signOut">
                    <i class="el-icon-circle-close"></i>退出登录
                </el-dropdown-item>
<!--                <el-dropdown-item @click.native="systemConfigurationShow=true">-->
<!--                    <i class="el-icon-setting"></i>系统配置-->
<!--                </el-dropdown-item>-->
            </el-dropdown-menu>
        </el-dropdown>
        <el-dialog
            class="editDialog"
            :append-to-body="true"
            width="70vw"
            :visible.sync="systemConfigurationShow"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :show-close="true"
        >
            <div slot="title" class="title">系统配置</div>
            <div class="content" v-loading="systemConfigurationLoading">
                <el-form label-width="0" ref="form">
                    <el-form-item v-for="n in systemConfigurationData">
                        <el-input v-model="n.value" placeholder="请输入" @keyup.enter.native="systemConfigurationSave">
                            <el-input slot="prepend" placeholder="" :value="n|label" @input="n.label=$event"  ></el-input>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" v-show="!systemConfigurationLoading">
                <el-button type="primary" :loading="systemConfigurationSaveLoading" @click="systemConfigurationSave">保存</el-button>
                <el-button type="info" plain @click="systemConfigurationShow=false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {mapState} from "vuex";
import {loginOut} from "@/api/controller/admin/login";

export default {
    components: {
    },
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
    },
    name: "Header",
    data() {
        return {
            activeMenu:'',
            logo: require("@/assets/images/logo.png"),
            history:{},
            systemConfigurationShow:false,
            systemConfigurationLoading:true,
            systemConfigurationSaveLoading:false,
            systemConfigurationData:[],
        };
    },
    watch: {
        systemConfigurationShow(a){
            if(a){
                if(!this.systemConfigurationData.length){
                    this.systemConfigurationLoading = true;
                    this.$api['admin/systemConfig'].findAllKeys().then(res => {

                       this.systemConfigurationData = res.data;
                       this.systemConfigurationLoading = false;

                    }).catch(err => {
                        console.log(err)
                    });
                }
            }
        },
        $route:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (to){
                try {
                    this.activeMenu = to.matched[0].name;
                }catch (e) {

                };
            }
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
            menus: state => state.user.menus,
        })
    },
    created() {},
    methods: {
        beforeLeave(a,b){
            //this.history[b] = this.$route.name
        },
        menuClick(){

            var getChildren = (arr,code)=>{

                arr.some(r=>{
                    if(r.code==code){

                        if (r.children && r.children.length > 0) {
                            if(this.history[code]){
                                this.$router.push({
                                    name:this.history[code]
                                })
                            }else {
                                getChildren(r.children, r.children[0].code)
                            }
                        } else {
                            this.$router.push({
                                name: r.code
                            })
                        }
                    }
                })

            }
            getChildren(this.menus,this.activeMenu)
        },
        signOut() {
            this.$api['admin/login'].loginOut().then(res => {

                this.$store.commit({
                    type: 'user/SIGN_OUT'
                });
                this.$router.push('/login');

            }).catch(err => {
                console.log(err)
            });
        },
        systemConfigurationSave(){
            var arr = []
            this.systemConfigurationData.some(r=>{
                arr.push( this.$api['admin/systemConfig'].save({
                    id:r.id,
                    key: r.key,
                    label:r.label,
                    value: r.value,
                }))
            });
            this.systemConfigurationSaveLoading = true;
            Promise.all(arr).then(() => {

                this.$message.success("操作成功！");
                this.systemConfigurationSaveLoading = false;
            }).catch(err => {
                console.log(err)
                this.systemConfigurationSaveLoading = false;
            });
        },
    },
    filters:{
        label(v){
            var obj = {
                LOG_BACK_POLICY:'日志备份策略',
                LOG_BACK_ADDRESS:'日志备份路劲',
                SYSTEM_BACK_POLICY:'系统数据备份策略',
                SYSTEM_BACK_ADDRESS:'系统数据备份路劲',
                CYCLE_POLICY:'密码周期',
                TOKEN_EXPIRATION_TIME:'token过期时间',
            };
            return v.label||obj[v.key]||v.key;
        }
    }
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

.header {
    .logo {
        font-family: "TTTGB-Medium";
        float:left;
        padding:0 25px;
        line-height:56px;
        font-size:20px;

        .line{
            width: 2px;
            height: 16px;
            margin: 20px 8px 0 8px;
            border-left: 2px solid #ffffff;
        }

        .assistant{
            display: inline-block;
            font-family: "微软雅黑";
            font-size: 14px;
            line-height: 25px;
            color: #00B8FF;
            background-color: #ffffff;
            padding: 0 11px;
            margin-top: 16px;
            border-radius: 4px;
        }
    }
    /deep/.nav {
        float: left;
        margin:0 0 0 30px;
        width:calc(100% - 620px);
        height: 56px;

        .el-tabs__header {
            margin:0;
            .el-tabs__nav-wrap {
                &:after {display:none;}
            }
            .el-tabs__active-bar {display:none;}
            .el-tabs__item {
                padding:0 20px;
                height: 56px;
                line-height: 56px;
                color:#fff;
                font-size:16px;
                &.is-active{
                    .item {
                        &:before {
                            content:"";
                            position:absolute;
                            bottom:1px;
                            left:15px;
                            right:15px;
                            background:#fff;
                            height:2px;
                        }
                    }
                    //background:rgba(255,255,255,.2);
                }
                .item {
                    position:relative;
                    min-width:80px;
                    text-align:center;
                    line-height:56px;
                    font-size:14px;
                    .iconfont {
                        display:none;
                        padding:15px 0 0 0;
                        font-size:30px;
                    }
                }
            }
            .el-tabs__nav-prev,
            .el-tabs__nav-next {
                width:20px;
                height:100%;
                text-align:center;
                i {
                    position:absolute;
                    top:50%;
                    left:0;
                    width:100%;
                    color:#fff;
                    line-height:20px;
                    margin-top:-10px;
                    font-size:20px;
                }
            }
        }
    }
    .user {
        float: right;
        margin: 0 15px 0 0;
        line-height:56px;
        .portrait {
            width:24px;
            height:24px;
            border-radius:50%;
            background:#F6F9FD;
            margin-right: 10px;
            border:#ddd solid 1px;
            vertical-align: middle;
        }
    }
}
/deep/.content{
    .el-input-group__prepend {
        padding:0;
        .el-input {
            width:180px;
            .el-input__inner {
                border:none;
                height:38px;
                line-height:38px;
                text-align:right;
            }
        }
    }
}
</style>
